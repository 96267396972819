/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-destructuring */
<template>
  <b-card>
    <b-form
      @submit.prevent="submitForm"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Name"
            label-for="h-name"
            label-cols-md="2"
          >
            <b-form-input
              id="h-name"
              v-model="domain.name"
              placeholder="Name"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Email"
            label-for="h-email"
            label-cols-md="2"
          >
            <b-form-input
              id="h-email"
              v-model="domain.email"
              type="email"
              placeholder="Email"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Url"
            label-for="h-url"
            label-cols-md="2"
          >
            <b-form-input
              id="h-url"
              v-model="domain.url"
              placeholder="Url"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <h4 class="w-100 ml-1 my-2">
          SMTP
        </h4>
        <b-col cols="6">
          <b-form-group
            label="SMTP Username"
            label-for="h-smtp-username"
            label-cols-md="4"
          >
            <b-form-input
              id="h-smtp-username"
              v-model="domain.smtp.username"
              placeholder="SMTP Username"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="SMTP Password"
            label-for="h-smtp-password"
            label-cols-md="4"
          >
            <b-form-input
              id="h-smtp-password"
              v-model="domain.smtp.password"
              placeholder="SMTP Password"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="SMTP Port"
            label-for="h-smtp-port"
            label-cols-md="4"
          >
            <b-form-input
              id="h-smtp-port"
              v-model="domain.smtp.port"
              placeholder="SMTP Port"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="SMTP Secure"
            label-for="h-smtp-secure"
            label-cols-md="4"
          >
            <b-form-input
              id="h-smtp-secure"
              v-model="domain.smtp.secure"
              placeholder="SMTP Secure"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="SMTP NoReply"
            label-for="h-smtp-noReply"
            label-cols-md="4"
          >
            <b-form-input
              id="h-smtp-noReply"
              v-model="domain.smtp.noReply"
              placeholder="SMTP NoReply"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <h4 class="w-100 ml-1 my-2">
          IMAP
        </h4>
        <b-col cols="6">
          <b-form-group
            label="IMAP Username"
            label-for="h-imap-username"
            label-cols-md="4"
          >
            <b-form-input
              id="h-imap-username"
              v-model="domain.imap.username"
              placeholder="IMAP Username"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="IMAP Password"
            label-for="h-imap-password"
            label-cols-md="4"
          >
            <b-form-input
              id="h-imap-password"
              v-model="domain.imap.password"
              placeholder="IMAP Password"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="IMAP Port"
            label-for="h-imap-port"
            label-cols-md="4"
          >
            <b-form-input
              id="h-imap-port"
              v-model="domain.imap.port"
              placeholder="IMAP Port"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="IMAP Host"
            label-for="h-imap-host"
            label-cols-md="4"
          >
            <b-form-input
              id="h-imap-host"
              v-model="domain.imap.host"
              placeholder="IMAP Host"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="IMAP TLS"
            label-for="h-imap-tls"
            label-cols-md="4"
          >
            <b-form-input
              id="h-imap-tls"
              v-model="domain.imap.tls"
              placeholder="IMAP TLS"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <!-- submit and reset -->
        <b-col offset-md="4">
          <b-button
            type="submit"
            variant="primary"
            class="mr-1 float-right"
          >
            Submit
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
/* eslint-disable */

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard,
} from 'bootstrap-vue'

export default {
  name: 'CreateDomain',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
  },
  data() {
    return {
      domain: {
        name: '',
        url: '',
        email: '',
        smtp: {
          username: '',
          password: '',
          port: '',
          secure: '',
          noReply: '',
        },
        imap: {
          username: '',
          password: '',
          host: '',
          port: '',
          tls: '',
        },
        status: 'active',
      },
    }
  },
  methods: {
    submitForm() {
      const self = this

      this.$store.dispatch('app/createDomain', this.domain).catch().then(() => {
        self.$router.push('/domains')
      })
    }
  },
}
</script>
